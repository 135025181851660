
import { Hore } from "@/type";
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomePart1",
  data() {
    return {
      items: <Hore[]>[
        {
          active: false,
          imgs: [{
            active: true,
            src: '/img/home/part-1/_Icon0001.png',
          }],
          bodies: [
            { title: '引入期', content: '将定向运动看作一种军事体育项目，以军队和教育部门的军事训练、测绘部门的科普工作中心进行。' }
          ]
        },
        {
          active: false,
          imgs: [{
            active: true,
            src: '/img/home/part-1/_Icon0002.png',
          }],
          bodies: [
            { title: '推广期', content: '1994年首届全国定向运动锦标赛；教育系统在推广过程中发挥重要作用。' }
          ]
        },
        {
          active: false,
          imgs: [{
            active: true,
            src: '/img/home/part-1/_Icon0003.png',
          }],
          bodies: [
            { title: '发展期', content: '开始与国际接轨、合作；引入学校体育课程；与旅游、休闲结合起来' }
          ]
        },
      ],
    };
  },
});
