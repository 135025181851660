
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomePart6',
  setup() {
    return {
      items: [
        {
          src: '/img/home/part-9/_Img0001.png',
          alt: '速风体育俱乐部'
        },
        {
          src: '/img/home/part-9/_Img0002.png',
          alt: '青天寨'
        },
        {
          src: '/img/home/part-9/_Img0003.png',
          alt: '心念意合'
        },
        {
          src: '/img/home/part-9/_Img0004.png',
          alt: '白鹭营地'
        },
        {
          src: '/img/home/part-9/_Img0005.png',
          alt: '泰禹小学'
        },
        {
          src: '/img/home/part-9/_Img0006.png',
          alt: '砂子塘小学'
        },
        {
          src: '/img/home/part-9/_Img0007.png',
          alt: '枫树山小学'
        },
        {
          src: '/img/home/part-9/_Img0008.png',
          alt: '桂花坪小学'
        },
      ],
    };
  },
  methods: {
  }
});
