
import PartTitle from "./PartTitle.vue";
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomePart6',
  components: { PartTitle },
  setup() {
    return {
      items: [
        {
          src: '/img/home/part-6/_Img0001.png',
          title: '指寻·定向运动智慧教学设备箱(专业版)',
          href: '/equipment#1',
        },
        {
          src: '/img/home/part-6/_Img0001.png',
          title: '指寻·定向运动智慧教学设备箱(标准版)',
          href: '/equipment#2',
        },
        {
          src: '/img/home/part-6/_Img0001.png',
          title: '指寻·定向运动智慧教学设备箱(教辅版)',
          href: '/equipment#3',
        },
      ],
    };
  },
});
