
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PartTitle',
  props: {
    src: { type: String, required: true },
    alt: { type: String },
  }
});
