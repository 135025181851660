// 首页Banner
export const HomeBanner = [
  {
    "active": true,
    "src": "/img/banner/home-1.jpg",
    "title": "定向运动全流程服务提供服务商",
    "content": "",
    "fontSize": "105%"
  },
  {
    "src": "/img/banner/home-2.jpg",
    "title": "不一样的智慧体育团建",
    "content": "",
    "fontSize": "105%"
  }
];

// Saas方案Banner
export const SaasBanner = [
  {
    "active": true,
    "src": "/img/banner/saas-1.jpg",
  },
];

// 基础设施Banner
export const InfrastructureBanner = [
  {
    "active": true,
    "src": "/img/banner/infrastructure-1.jpg",
  },
];

// 关于我们
export const AboutBanner = [
  {
    "active": true,
    "src": "/img/banner/about-1.jpg",
  },
];

// 申请试用Banner
export const TrialBanner = [
  {
    "active": true,
    "src": "/img/banner/home-1.jpg",
  },
];