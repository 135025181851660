<template>
<div class="contact-us">
  <div class="container">
    <div class="row">
      <div class="col-lg-7">
        <div class="d-flex flex-column">
          <div class="display-5 contact-us-title">联系我们/Contact Us</div>

          <div class="entry-list">
            <div class="entry">
              <img class="icon" src="@/assets/img/icon-location.png" alt="Location">
              <div class="content">
                <p>长沙市岳麓区西湖街道西湖公园58小镇A1区二楼212趣寻体育</p>
              </div>
            </div>
            <div class="entry">
              <img class="icon" src="@/assets/img/icon-tel.png" alt="TEL">
              <div class="content">
                <p>商务联系： 175 0844 8450</p>
                <!-- <p>微信扫码添加好友</p> -->
                <!-- <img src="/img/wechat-qr-code.png" alt="WeChat QR Code" class="img-fluid rounded-3" /> -->
              </div>
            </div>
          </div>

          <div class="content">
            <p>版权所有：湖南长沙趣寻体育科技有限公司</p>
            <a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备2022002228号-1</a>
          </div>
        </div>
      </div>

      <div class="col-lg">
        <a href="https://amap.com/place/B0KRF5SXUC" target="_blank">
          <img src="/img/map.jpg" class="img-fluid rounded-3" alt="Map">
        </a>
      </div>
    </div>
  </div>
</div>
</template>

<style lang="scss" scoped>
.contact-us {
  width: 100%;
  background-size: cover;
  background-image: url(@/assets/img/contact-us.jpg);
  padding-top: 5%;
  padding-bottom: 5%;

  .contact-us-title {
    font-family: PingFangSC-Medium, PingFang SC;
    color: #000000;
    padding: 10px 0;
  }

  .entry-list {
    flex-grow: 1;
    max-width: 85%;

    .entry {
      display: flex;
      margin: 10px 0;

      .icon {
        width: 22px;
        height: 22px;
        margin-right: 10px;
        line-height: 22px;
      }
    }
  }

  .content {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 22px;

    img {
      width: 96px;
    }

    a {
      text-decoration: none;
    }
  }
}
</style>
