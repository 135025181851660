
import ContactUs from '@/components/ContactUs.vue';
import router from '@/router';
import { defineComponent } from 'vue';
import LogoSvg from './components/LogoSvg.vue';

export default defineComponent({
  name: 'App',
  components: {
    LogoSvg,
    ContactUs,
  },
  setup() {
    return {
      navItems: [
        {
          to: '/',
          text: '首页',
        },
        {
          to: '/saas',
          text: 'Saas方案',
        },
        {
          to: '/infrastructure',
          text: '基础设施',
        },
        {
          to: '/about',
          text: '关于我们',
        },
        {
          to: '/trial',
          text: '申请试用',
          class: 'trial',
        }
      ],
    };
  },
  computed: {
    banners(): Array<any> {
      return router.currentRoute.value.meta.banner! as Array<any> ?? [];
    },
    contactUs(): Boolean {
      return ((router.currentRoute.value.meta.contactUs as any) ?? true);
    }
  },
  methods: {
    handleScroll() {
      if (!this.banners.length) return;
      const scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop;
      const navbar = document.querySelector('.navbar-1');
      if (scrollTop > 10) {
        navbar?.classList.add('scroll');
      } else {
        navbar?.classList.remove('scroll');
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
});
