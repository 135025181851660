
import PartTitle from "./PartTitle.vue";
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomePart8',
  components: { PartTitle },
  setup() {
    return {
      items: [
        {
          title: {
            name: '指寻app',
            type: '用户端',
            isoUrl: 'https://itunes.apple.com/cn/app/id1610444227',
            androidUrl: 'https://www.pgyer.com/By9T',
          },
          desc: '指寻用户端是湖南趣寻体育科技有限公司面向定向运动爱好者推出的手机端app，用户可在系统内完成活动报名、参赛签到、成绩排名、查看轨迹等一系列功能。',
          items: [
            [
              {
                src: '/img/home/part-8/_Img0001.png',
                label: '首页'
              },
              {
                src: '/img/home/part-8/_Img0002.png',
                label: '活动详情'
              },
              {
                src: '/img/home/part-8/_Img0003.png',
                label: '活动列表'
              },
              {
                src: '/img/home/part-8/_Img0004.png',
                label: '活动报名'
              }
            ],
            [
              {
                src: '/img/home/part-8/_Img0005.png',
                label: '活动监控'
              },
              {
                src: '/img/home/part-8/_Img0006.png',
                label: '活动轨迹'
              },
              {
                src: '/img/home/part-8/_Img0007.png',
                label: '成绩排名'
              },
              {
                src: '/img/home/part-8/_Img0008.png',
                label: '成绩评估'
              }
            ],
          ],
        },
        {
          title: {
            name: '指寻app',
            type: '机构端',
            isoUrl: 'https://itunes.apple.com/cn/app/id1610669472',
            androidUrl: 'https://www.pgyer.com/sX1w',
          },
          desc: '指寻机构端是湖南趣寻体育科技有限公司面向定向运动组织者推出的手机端app，用户可在系统内完成活动发布、教学管理、活动监控、成绩查询、财务管理等一系列功能。',
          class: ['bg-gray'],
          items: [
            [
              {
                src: '/img/home/part-8/_Img0009.png',
                label: '活动发布'
              },
              {
                src: '/img/home/part-8/_Img0010.png',
                label: '课表'
              },
              {
                src: '/img/home/part-8/_Img0011.png',
                label: '活动安排'
              },
              {
                src: '/img/home/part-8/_Img0012.png',
                label: '成绩列表'
              }
            ],
          ],
        },
      ],
      items1: [
        {
          src: '/img/home/part-8/_Img0001.png',
          label: '首页'
        },
        {
          src: '/img/home/part-8/_Img0002.png',
          label: '活动详情'
        },
        {
          src: '/img/home/part-8/_Img0003.png',
          label: '活动列表'
        },
        {
          src: '/img/home/part-8/_Img0004.png',
          label: '活动报名'
        }
      ],
      items2: [
        {
          src: '/img/home/part-8/_Img0005.png',
          label: '活动监控'
        },
        {
          src: '/img/home/part-8/_Img0006.png',
          label: '活动轨迹'
        },
        {
          src: '/img/home/part-8/_Img0007.png',
          label: '成绩排名'
        },
        {
          src: '/img/home/part-8/_Img0008.png',
          label: '成绩评估'
        }
      ],
      items3: [
        {
          src: '/img/home/part-8/_Img0009.png',
          label: '活动发布'
        },
        {
          src: '/img/home/part-8/_Img0010.png',
          label: '课表'
        },
        {
          src: '/img/home/part-8/_Img0011.png',
          label: '活动安排'
        },
        {
          src: '/img/home/part-8/_Img0012.png',
          label: '成绩列表'
        }
      ]
    };
  },
  computed: {
    isWeiXin() {
      const ua = navigator.userAgent.toLowerCase();
      return ua.indexOf('micromessenger') !== -1;
    }
  },
  methods: {
    download(url1: string, url2: string) {
      if (this.isWeiXin) {

      }
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.open(url1);
      } else {
        window.open(url2);
      }
    },
  }
});
