
import PartTitle from "./PartTitle.vue";
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomePart5',
  components: { PartTitle },
  setup() {
    return {
      items: [
        {
          title: '俱乐部',
          items: [
            {
              img: {
                src: '/img/home/part-5/_Img0001.png',
                alt: '三点半',
              },
              label: '三点半',
            },
            {
              img: {
                src: '/img/home/part-5/_Img0002.png',
                alt: '校队',
              },
              label: '校队',
            },
            {
              img: {
                src: '/img/home/part-5/_Img0003.png',
                alt: '周末活动',
              },
              label: '周末活动',
            },
            {
              img: {
                src: '/img/home/part-5/_Img0004.png',
                alt: '俱乐部',
              },
              label: '俱乐部',
            },
          ]
        },
        {
          title: '学校',
          items: [
            {
              img: {
                src: '/img/home/part-5/_Img0005.png',
                alt: '赛事',
              },
              label: '赛事',
            },
            {
              img: {
                src: '/img/home/part-5/_Img0002.png',
                alt: '校队',
              },
              label: '校队',
            },
            {
              img: {
                src: '/img/home/part-5/_Img0007.png',
                alt: '校本课',
              },
              label: '校本课',
            },
            {
              img: {
                src: '/img/home/part-5/_Img0008.png',
                alt: '示范课',
              },
              label: '示范课',
            },
          ]
        },
        {
          title: '营地',
          items: [
            {
              img: {
                src: '/img/home/part-5/_Img0009.png',
                alt: '散客',
              },
              label: '散客',
            },
            {
              img: {
                src: '/img/home/part-5/_Img0010.png',
                alt: '研学',
              },
              label: '研学',
            },
            {
              img: {
                src: '/img/home/part-5/_Img0011.png',
                alt: '团建',
              },
              label: '团建',
            },
            {
              img: {
                src: '/img/home/part-5/_Img0005.png',
                alt: '赛事',
              },
              label: '赛事',
            },
          ]
        },
      ],
    };
  },
  methods: {
  }
});
