
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomePart7',
  setup() {
    return {
      items1: [
        {
          src: '/img/home/part-7/_Img0001.png',
          title: '智能',
          content: '平台自动成统排名，无需人工统计排名，节省人力和时间成本'
        },
        {
          src: '/img/home/part-7/_Img0002.png',
          title: '多样',
          content: '强大的Saas平台为机构提供多业务场景支撑，如：专业赛事、校内活动、周末活动、团建活动等'
        },
        {
          src: '/img/home/part-7/_Img0003.png',
          title: '精准',
          content: '打卡时间可精确到毫秒，便于复盘和训练计划调整'
        },
        {
          src: '/img/home/part-7/_Img0004.png',
          title: '便捷',
          content: '赛前无需手动校时，免除繁琐的校时操作'
        },
        {
          src: '/img/home/part-7/_Img0005.png',
          title: '安全',
          content: 'GPS定位，可实时记录参与者位置和轨迹，支持一键导航到参与者位置和电子围栏报警'
        }
      ],
      items2: [
        {
          title: '购买设备+地图+平台方执行',
          src: '/img/home/part-7/_Img0006.png',
          content: '注：每场5:5分成，单场活动报名88元/人，每场获得收入44元/人'
        },
        {
          title: '购买设备+地图+平台方、营地执行',
          src: '/img/home/part-7/_Img0007.png',
          content: '注：每场只需6元/人平台使用费'
        }
      ],
    };
  },
});
