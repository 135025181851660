
import PartTitle from "./PartTitle.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomePart2",
  components: { PartTitle },
  setup() {
    return {
      items: [
        {
          src: '/img/home/part-2/_Img0001.png',
          title: '紧跟发展潮流',
          content: '习近平总书记在党的二十大报告中明确指出：“要广泛开展全民健身活动，加强青少年体育工作，促进群众体育和竞技体育全面发展，加快建设体育强国。'
        },
        {
          src: '/img/home/part-2/_Img0002.png',
          title: '适用人群广泛',
          content: '定向运动几乎适合所有人群参与。男女老少都可以自由选择其中一种适合自己参与的定向运动形式，并从中达到娱乐、益智、健身的效果',
        },
        {
          src: '/img/home/part-2/_Img0003.png',
          title: '运动场地不限',
          content: '定向运动项目无论是在公园、营地、农庄，还是在学校、商场等场地都可开展',
        },
        {
          src: '/img/home/part-2/_Img0004.png',
          title: '开展形式多样',
          content: '定向运动融合度高，与学科、安全、社交、生活等均可融合',
        },
        {
          src: '/img/home/part-2/_Img0005.png',
          title: '投资成本低',
          content: '仅需一套定向设备即可开展活动，无需场地翻新、无需破坏原有设施',
        },
      ],
    };
  },
});
