
import { defineComponent } from 'vue';
import PartCarousel2 from '@/components/PartCarousel2.vue';

export default defineComponent({
  name: 'HomePart3',
  components: { PartCarousel2 },
  setup() {
    return {
      items: [
        {
          title: '定向运动核心助力',
          subtitle: '俱乐部',
          content: '定向运动助力青少年提升体魄',
          img: '/img/home/part-3/1.jpg',
        }, {
          title: '定向运动核心助力',
          subtitle: '营地',
          content: '定向运动助力营地响应乡村振兴',
          img: '/img/home/part-3/2.jpg',
        }, {
          title: '定向运动核心助力',
          subtitle: '学校',
          content: '定向运动助力智慧校园开展智慧运动',
          img: '/img/home/part-3/3.jpg',
        },
      ],
    };
  },
});
