import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomePart1 = _resolveComponent("HomePart1")!
  const _component_HomePart2 = _resolveComponent("HomePart2")!
  const _component_HomePart3 = _resolveComponent("HomePart3")!
  const _component_HomePart4 = _resolveComponent("HomePart4")!
  const _component_HomePart5 = _resolveComponent("HomePart5")!
  const _component_HomePart6 = _resolveComponent("HomePart6")!
  const _component_HomePart7 = _resolveComponent("HomePart7")!
  const _component_HomePart8 = _resolveComponent("HomePart8")!
  const _component_HomePart9 = _resolveComponent("HomePart9")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HomePart1),
    _createVNode(_component_HomePart2),
    _createVNode(_component_HomePart3),
    _createVNode(_component_HomePart4),
    _createVNode(_component_HomePart5),
    _createVNode(_component_HomePart6),
    _createVNode(_component_HomePart7),
    _createVNode(_component_HomePart8),
    _createVNode(_component_HomePart9)
  ], 64))
}