
import { defineComponent } from 'vue';
import PartTitle from "./PartTitle.vue";

export default defineComponent({
  name: 'HomePart4',
  components: { PartTitle },
  setup() {
    return {
      items1: [
        {
          img: {
            src: 'img/home/part-4/_Img0001.png',
            alt: '俱乐部想要开展定向运动?',
          },
          title: '没有合适的定向运动场地？',
          content: '指寻提供<br>定向专项公园、营地可供选择'
        },
        {
          img: {
            src: 'img/home/part-4/_Img0002.png',
            alt: '俱乐部想要开展定向运动?',
          },
          title: '没有定向运动专业设备？',
          content: '指寻提供<br>专业设备可供租赁/购买'
        },
        {
          img: {
            src: 'img/home/part-4/_Img0003.png',
            alt: '俱乐部想要开展定向运动?',
          },
          title: '没有定向运动受众群体？',
          content: '指寻提供<br>定向垂直平台聚集广大定向爱好者'
        },

      ],
      items2: [
        {
          img: {
            src: 'img/home/part-4/_Img0004.png',
            alt: '俱乐部想要开展定向运动?',
          },
          title: '没有定向运动基础设施？',
          content: '指寻提供<br>专业制图员实地勘测、绘制地图、布置场地'
        },
        {
          img: {
            src: 'img/home/part-4/_Img0002.png',
            alt: '俱乐部想要开展定向运动?',
          },
          title: '没有定向运动专业设备？',
          content: '指寻提供<br>专业设备可供租赁/购买'
        },
        {
          img: {
            src: 'img/home/part-4/_Img0005.png',
            alt: '俱乐部想要开展定向运动?',
          },
          title: '没有人员能开展定向运动？',
          content: '指寻提供<br>专业教练进行系统培训、要点讲解'
        },
      ],
    };
  },
});
