import { AboutBanner, HomeBanner, InfrastructureBanner, SaasBanner, TrialBanner } from '@/banner';
import HomeView from '@/views/HomeView.vue'; // 首页
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  /** 首页 */
  {
    path: '/',
    meta: { banner: HomeBanner },
    component: HomeView,
  },
  /** Saas方案 */
  {
    path: '/saas',
    meta: { banner: SaasBanner },
    component: () => import('@/views/SaasView.vue'),
  },
  /** 基础设施 */
  {
    path: '/infrastructure',
    meta: { banner: InfrastructureBanner },
    component: () => import('@/views/InfrastructureView.vue'),
  },
  /** 关于我们 */
  {
    path: '/about',
    meta: { banner: AboutBanner },
    component: () => import('@/views/AboutView.vue'),
  },
  /** 申请试用 */
  {
    path: '/trial',
    meta: { banner: TrialBanner, contactUs: false },
    component: () => import('@/views/TrialView.vue'),
  },
  /** 智慧套装 */
  {
    path: '/equipment',
    component: () => import('@/views/EquipmentView.vue'),
  },
];

const router = createRouter({
  linkActiveClass: 'active',
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, _from, savedPosition) {
    if (to.hash) {
      return { el: to.hash };
    } else if (savedPosition) {
      return savedPosition;
    }

    return { top: 0 };
  },
  routes,
});

export default router;
