
import HomePart1 from '@/components/home/HomePart1.vue';
import HomePart2 from '@/components/home/HomePart2.vue';
import HomePart3 from '@/components/home/HomePart3.vue';
import HomePart4 from '@/components/home/HomePart4.vue';
import HomePart5 from '@/components/home/HomePart5.vue';
import HomePart6 from '@/components/home/HomePart6.vue';
import HomePart7 from '@/components/home/HomePart7.vue';
import HomePart8 from '@/components/home/HomePart8.vue';
import HomePart9 from '@/components/home/HomePart9.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    HomePart1,
    HomePart2,
    HomePart3,
    HomePart4,
    HomePart5,
    HomePart6,
    HomePart7,
    HomePart8,
    HomePart9
  },
});
